// src/components/Waitlist/Waitlist.tsx
import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Alert,
  SelectChangeEvent,
} from "@mui/material";
import Footer from "../Footer/Footer";
import api from "../../api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Waitlist: React.FC = () => {
  //   const [accessToken, setAccessToken] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    consent: false,
    otherRoleDescription: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Fetch access token when the component mounts
  //   useEffect(() => {
  //     const fetchAccessToken = async () => {
  //       try {
  //         const response = await axios.get("/api/get-access-token");
  //         setAccessToken(response.data.accessToken);
  //       } catch (err) {
  //         console.error("Error fetching access token:", err);
  //         setError("Unable to obtain access token. Please try again later.");
  //       }
  //     };

  //     fetchAccessToken();
  //   }, []);

  // Handle form field changes
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: (event.target as HTMLInputElement).checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle role selection
  const handleRoleChange = (event: SelectChangeEvent<string>) => {
    setFormData((prevData) => ({
      ...prevData,
      role: event.target.value as string,
    }));
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Basic form validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.role ||
      (formData.role === "other" && !formData.otherRoleDescription) // Check if otherRoleDescription is provided
    ) {
      setError("Please fill in all required fields and agree to the terms.");
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);
    let recaptchaToken = null;

    if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha("waitlist_form");
    }

    if (!recaptchaToken) {
      setError("reCAPTCHA verification failed. Please try again.");
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/waitlist", {
        name: formData.name,
        email: formData.email,
        role: formData.role,
        otherRoleDescription: formData.otherRoleDescription,
        consent: formData.consent,
        recaptchaToken,
      });

      if (response.status === 201) {
        setSuccess(true);
        setFormData({
          name: "",
          email: "",
          role: "",
          otherRoleDescription: "",
          consent: false,
        });
      } else {
        setError("An unexpected error occurred. Please try again later.");
      }
    } catch (err) {
      console.error("Error submitting waitlist form:", err);
      setError("Failed to submit your information. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" gutterBottom>
          Join Our Private Beta
        </Typography>
        <Typography variant="body1" paragraph>
          We're currently in private beta. Sign up below, and we'll keep you
          updated on our progress!
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Thank you for joining the list! We'll be in touch soon.
          </Alert>
        )}

        <Box component="form" sx={{ mt: 4 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Name */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                required
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                type="email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>

            {/* Role */}
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  value={formData.role}
                  label="Role"
                  name="role"
                  onChange={handleRoleChange}
                >
                  <MenuItem value="producer">Producer</MenuItem>
                  <MenuItem value="brand">Brand</MenuItem>
                  <MenuItem value="other">Other</MenuItem> {/* Added Other */}
                </Select>
              </FormControl>
            </Grid>

            {formData.role === "other" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Please specify your role"
                  variant="outlined"
                  required
                  name="otherRoleDescription"
                  value={formData.otherRoleDescription}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {/* Consent */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                  />
                }
                label="I agree to be contacted regarding updates and opportunities."
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Waitlist;
