import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const WaitlistSection = () => {
  return (
    <Container sx={{ mt: 8, textAlign: "center" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Have questions?
      </Typography>
      <Button
        variant="contained"
        size="large"
        color="secondary"
        component={Link}
        to="/contact"
        sx={{ mt: 2 }}
      >
        Contact us
      </Button>
    </Container>
  );
};

export default WaitlistSection;
