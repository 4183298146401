import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const WaitlistSection = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "primary.contrastText",
        py: 8,
        mt: 8,
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom color="secondary">
        Join the waiting list
      </Typography>
      <Typography variant="body1" gutterBottom color="secondary">
        We are currently in private beta. Sign up to get early access!
      </Typography>
      <Button
        component={Link}
        to="/waitlist"
        variant="contained"
        color="secondary"
        size="large"
      >
        Join Waiting List Now
      </Button>
    </Box>
  );
};

export default WaitlistSection;
