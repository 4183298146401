// src/components/HomePage/HomePage.tsx
import React from "react";
import { Box } from "@mui/material";
import ProducerHomePage from "./ProducerHomePage";
import BrandHomePage from "./BrandHomePage";
import WaitlistSection from "../Waitlist/WaitlistSection";
import Footer from "../Footer/Footer";
import ContactSection from "../Common/ContactSection";

interface HomePageProps {
  role: "producer" | "brand";
}

const HomePage: React.FC<HomePageProps> = ({ role }) => {
  return (
    <Box>
      {role === "producer" ? <ProducerHomePage /> : <BrandHomePage />}
      <ContactSection />

      <WaitlistSection />
      <Footer />
    </Box>
  );
};

export default HomePage;
