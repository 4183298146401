// src/components/Pages/TermsPage.tsx
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Footer from "../Footer/Footer";

const TermsPage: React.FC = () => {
  return (
    <Box>
      {/* Main Content */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          {/* Placeholder text - replace with your actual terms of service */}
          Welcome to Media Brand Match. Please read these Terms of Service
          ("Terms") carefully before using our platform.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing or using the Media Brand Match platform ("Service"), you
          agree to be bound by these Terms of Service ("Terms"). If you do not
          agree to these Terms, please do not use the Service.
        </Typography>
        {/* Add more sections as needed */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          2. Description of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Media Brand Match connects media producers with brands for
          collaborations. The Service includes features such as AI-powered
          matching, messaging systems, and a community network.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          3. Eligibility
        </Typography>
        <Typography variant="body1" paragraph>
          You must be at least 18 years old and capable of entering into a
          legally binding agreement to use the Service.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          4. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          Registration: You must create an account to access certain features.
          Provide accurate and complete information during registration. Account
          Security: You are responsible for maintaining the confidentiality of
          your account credentials and for all activities that occur under your
          account.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          5. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to: Violate any applicable laws or regulations. Post or
          transmit any fraudulent, misleading, or inappropriate content.
          Impersonate any person or entity or misrepresent your affiliation.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          6. Content Ownership and Licenses
        </Typography>
        <Typography variant="body1" paragraph>
          Your Content: You retain ownership of content you submit but grant
          Media Brand Match a non-exclusive, worldwide license to use it for
          providing the Service. Platform Content: All content provided by Media
          Brand Match is owned by us or our licensors and is protected by
          intellectual property laws.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          7. Payments and Fees
        </Typography>
        <Typography variant="body1" paragraph>
          Services Fees: Certain features may require payment of fees. All fees
          are non-refundable unless otherwise stated. Payment Terms: Payments
          are due at the time specified and must be made via the methods
          provided.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          8. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to suspend or terminate your access to the
          Service at our discretion, without notice, for conduct that we believe
          violates these Terms or is harmful to other users or the Service.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          9. Disclaimers and Limitations of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          Disclaimers: The Service is provided "as is" without warranties of any
          kind. Limitation of Liability: Media Brand Match will not be liable
          for any indirect, incidental, or consequential damages arising from
          your use of the Service.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          10. Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to indemnify and hold harmless Media Brand Match and its
          affiliates from any claims arising out of your use of the Service or
          violation of these Terms.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          11. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We may update these Terms from time to time. We will notify you of
          significant changes by posting a notice on our platform or sending an
          email.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          12. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at{" "}
          <a href="mailto:mediabrandmatch@gmail.com">
            mediabrandmatch@gmail.com
          </a>
          .
        </Typography>
      </Container>

      <Footer />
    </Box>
  );
};

export default TermsPage;
