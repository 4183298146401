// src/components/Navbar/Navbar.tsx
import React from "react";
import { AppBar, Toolbar, Typography, Box, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../assets/images/logo.png";
import theme from "../../theme";

interface NavbarProps {
  role: "producer" | "brand";
  onRoleChange: (role: "producer" | "brand") => void;
}

const Navbar: React.FC<NavbarProps> = ({ role, onRoleChange }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRoleChange = (
    event: React.MouseEvent<HTMLElement>,
    newRole: "producer" | "brand" | null
  ) => {
    if (newRole !== null) {
      onRoleChange(newRole);
    }
  };

  // Custom styled ToggleButtonGroup
  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: "50px",
    overflow: "hidden",
    height: "40px",
    width: "200px",
    margin: "0 auto",
  }));

  // Custom styled ToggleButton
  const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    flex: 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    border: "none",
    borderRadius: "0",
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    "&:not(.Mui-selected)": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

  return (
    <AppBar position="sticky" color="primary">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Link to={`/${role}`}>
            <img src={Logo} alt="Logo" style={{ height: "40px" }} />
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            component={Link}
            to={`/${role}`}
            sx={{ flexGrow: 1, textDecoration: "none", color: "inherit" }}
          >
            Media Brand Match
          </Typography>
        </Box>
        {isMobile && (
          <Box
            sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          ></Box>
        )}
        {!isMobile && (
          <Box>
            <StyledToggleButtonGroup
              value={role}
              exclusive
              onChange={handleRoleChange}
              aria-label="Role Switcher"
            >
              <StyledToggleButton value="producer" aria-label="Producer">
                Producer
              </StyledToggleButton>
              <StyledToggleButton value="brand" aria-label="Brand">
                Brand
              </StyledToggleButton>
            </StyledToggleButtonGroup>
          </Box>
        )}
      </Toolbar>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <StyledToggleButtonGroup
            value={role}
            exclusive
            onChange={handleRoleChange}
            aria-label="Role Switcher"
          >
            <StyledToggleButton value="producer" aria-label="Producer">
              Producer
            </StyledToggleButton>
            <StyledToggleButton value="brand" aria-label="Brand">
              Brand
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>
      )}
    </AppBar>
  );
};

export default Navbar;
