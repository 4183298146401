import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import HeroCarousel from "../HeroCarousel/HeroCarousel";
import {
  RocketLaunchOutlined,
  GroupWorkOutlined,
  CampaignOutlined,
  AddBusinessOutlined,
  FolderCopyOutlined,
  VerifiedOutlined,
  AutoFixHighOutlined,
} from "@mui/icons-material";

const BrandHomePage: React.FC = () => {
  return (
    <Box>
      <HeroCarousel role="brand" />
      <Container sx={{ mt: 8 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Welcome to <span style={{ color: "#f0a500" }}>Media Brand Match</span>
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Media Brand Match is a revolutionary platform designed to seamlessly
          connect brands with top-tier media producers. Whether you're looking
          to expand your brand's reach or enhance your production quality, our
          platform offers the tools and connections you need to succeed.
        </Typography>
      </Container>

      <Container sx={{ mt: 10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          How We Empower Brands
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={RocketLaunchOutlined}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 100 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Expand Your Reach
                </Typography>
                <Typography variant="body1">
                  Connect with top media productions to reach new and diverse
                  audiences worldwide.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={GroupWorkOutlined}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 100 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Targeted Audiences
                </Typography>
                <Typography variant="body1">
                  Align your brand with productions that resonate with your
                  specific target demographics.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={CampaignOutlined}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 100 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Create Brand Impact
                </Typography>
                <Typography variant="body1">
                  A brand placement in a media production can significantly
                  boost your brand's visibility and impact.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          How It Works
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <AddBusinessOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Register Your Brand
              </Typography>
              <Typography variant="body1">
                Create a profile detailing your brand's vision, goals, and
                target audience.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <FolderCopyOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Add Your Products
              </Typography>
              <Typography variant="body1">
                For each product, indicate the type of media partnership you are
                looking for.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <AutoFixHighOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Intelligent Matching
              </Typography>
              <Typography variant="body1">
                Our smart algorithms connect you with the best partners tailored
                to your specific needs and goals.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <VerifiedOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Secure Collaboration
              </Typography>
              <Typography variant="body1">
                Initiate partnerships and collaborate on projects that elevate
                your brand presence.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BrandHomePage;
