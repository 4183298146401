// src/components/Pages/PrivacyPolicyPage.tsx
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Footer from "../Footer/Footer";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box>
      {/* Main Content */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Media Brand Match ("we," "us," or "our") is committed to protecting
          your privacy. This Privacy Policy explains how we collect, use, and
          disclose information about you when you use our Service.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          Personal Information: Name, email address, phone number, company
          details, and other information you provide during registration.
        </Typography>
        <Typography variant="body1" paragraph>
          Usage Data: Information about how you use the Service, including IP
          address, browser type, pages viewed, and timestamps.
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies and Tracking Technologies: We use cookies to enhance your
          experience and collect usage data.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may use your information to:
        </Typography>
        <Typography variant="body1" paragraph>
          Provide, maintain, and improve the Service.
        </Typography>
        <Typography variant="body1" paragraph>
          Process transactions and send related information.
        </Typography>
        <Typography variant="body1" paragraph>
          Communicate with you about products, services, offers, and events.
        </Typography>
        <Typography variant="body1" paragraph>
          Monitor and analyze trends, usage, and activities.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with:
        </Typography>
        <Typography variant="body1" paragraph>
          Service Providers: Third-party vendors who assist with essential
          services like payment processing, data analysis, and communications.
        </Typography>
        <Typography variant="body1" paragraph>
          Business Transfers: In the event of a merger, acquisition, or asset
          sale, your information may be transferred.
        </Typography>
        <Typography variant="body1" paragraph>
          Legal Requirements: When required by law or to protect our rights.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          5. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          Account Information: You can update or delete your account information
          by logging into your account settings.
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies: You can set your browser to refuse cookies, but this may
          affect your ability to use certain features of the Service.
        </Typography>
        <Typography variant="body1" paragraph>
          Marketing Communications: You can opt out of receiving promotional
          emails by following the unsubscribe instructions.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          6. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement reasonable security measures to protect your information.
          However, no transmission over the Internet is completely secure.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          7. International Data Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          Your information may be transferred to and processed in countries
          other than your own. We ensure appropriate safeguards are in place.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          8. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our Service is not intended for individuals under 18. We do not
          knowingly collect personal information from children.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          9. Third-Party Links
        </Typography>
        <Typography variant="body1" paragraph>
          Our Service may contain links to third-party websites. We are not
          responsible for their privacy practices.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          10. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy periodically. We will notify you of
          significant changes by posting a notice on our platform or sending an
          email.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          11. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:mediabrandmatch@gmail.com">
            mediabrandmatch@gmail.com
          </a>
          .
        </Typography>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default PrivacyPolicyPage;
