// src/components/HeroCarousel/HeroCarousel.tsx
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import theme from "../../theme";

interface HeroCarouselProps {
  role: "producer" | "brand";
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({ role }) => {
  const slides = role === "producer" ? producerSlides : brandSlides;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderArrowPrev = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) =>
    hasPrev &&
    !isMobile && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        sx={{
          position: "absolute",
          top: "calc(50% - 20px)",
          left: "10px",
          color: "secondary.main", // Use your brand color
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional background
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
          zIndex: 2,
        }}
      >
        <ArrowBackIosNew sx={{ fontSize: "2rem" }} />
      </IconButton>
    );

  const renderArrowNext = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) =>
    hasNext &&
    !isMobile && (
      <IconButton
        onClick={onClickHandler}
        title={label}
        sx={{
          position: "absolute",
          top: "calc(50% - 20px)",
          right: "10px",
          color: "secondary.main", // Use your brand color
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional background
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
          zIndex: 2,
        }}
      >
        <ArrowForwardIos sx={{ fontSize: "2rem" }} />
      </IconButton>
    );
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      swipeScrollTolerance={50}
      preventMovementUntilSwipeScrollTolerance={true}
    >
      {slides.map((slide, index) => (
        <div key={index} style={{ position: "relative" }}>
          <video
            src={`/videos/${slide.videoSrc}`}
            autoPlay
            muted
            loop
            playsInline
            style={{ width: "100%", height: "80vh", objectFit: "cover" }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(13, 27, 42, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              textAlign: "center",
              px: 2,
            }}
          >
            <Box>
              <Typography
                variant="h2"
                color="secondary"
                sx={{
                  fontSize: {
                    xs: "2rem",
                    sm: "3rem",
                    md: "4rem",
                  },
                  fontWeight: 700,
                }}
              >
                {slide.title}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                color="secondary"
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1.5rem",
                  },
                }}
              >
                {slide.subtitle}
              </Typography>
              <Button
                sx={{ mt: 8 }}
                variant="contained"
                size="large"
                color="secondary"
                href="/waitlist"
              >
                Join Waiting List Now
              </Button>
            </Box>
          </Box>
        </div>
      ))}
    </Carousel>
  );
};

const producerSlides = [
  {
    videoSrc: "director_seat.mp4",
    title: "Direct Your Vision With Realism",
    subtitle:
      "Collaborate with brands to bring your stories to life and give them authenticity.",
  },
  {
    videoSrc: "green_screen_set.mp4",
    title: "Expand Your Creative Horizons",
    subtitle:
      "Access resources, props and locations that elevate your production quality.",
  },
  {
    videoSrc: "filming_skateboard.mp4",
    title: "Capture Dynamic Scenes",
    subtitle: "Find brands that enhance your storytelling.",
  },
  {
    videoSrc: "breakfast_restaurant.mp4",
    title: "Unlock Exclusive Locations",
    subtitle:
      "Partner with your local locations for unique shooting environments that fit your needs.",
  },
  {
    videoSrc: "product_filming_set.mp4",
    title: "Creative Product Integration",
    subtitle:
      "Incorporate brands authentically into your narrative, leveraging your creative vision.",
  },
];

const brandSlides = [
  {
    videoSrc: "people_discussing_product.mp4",
    title: "Showcase Your Brand",
    subtitle:
      "Get featured in top media productions and reach new audiences where they can't miss you.",
  },
  {
    videoSrc: "product_filming_set.mp4",
    title: "Step into the Spotlight",
    subtitle: "Highlight your products in compelling stories seamlessly.",
  },
  {
    videoSrc: "video_games.mp4",
    title: "Engage the Gaming Community",
    subtitle:
      "Place your brand in popular video games across multple platforms and reach your audience where it matters most.",
  },
  {
    videoSrc: "man_vr.mp4",
    title: "Innovate with Virtual Reality",
    subtitle: "Immerse audiences in your brand experience, virtually.",
  },
];

export default HeroCarousel;
