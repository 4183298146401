import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Alert,
} from "@mui/material";
import Footer from "../Footer/Footer";
import api from "../../api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    let recaptchaToken = null;
    if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha("contact_form");
    }

    if (!recaptchaToken) {
      setError("reCAPTCHA verification failed. Please try again.");
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/contact", {
        ...formData,
        recaptchaToken,
      });
      if (response.status === 200) {
        setSuccess("Your message has been sent successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (err: any) {
      setError(
        err.response?.data?.message ||
          "An error occurred while sending your message."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          Have questions or need assistance? We're here to help! Fill out the
          form below or reach out to us directly at{" "}
          <a href="mailto:mediabrandmatch@gmail.com">
            mediabrandmatch@gmail.com
          </a>
          .
        </Typography>

        <Box component="form" sx={{ mt: 4 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                variant="outlined"
                required
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                variant="outlined"
                required
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                variant="outlined"
                type="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={6}
                required
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
            {success && (
              <Grid item xs={12}>
                <Alert severity="success">{success}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Message"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ContactPage;
