// src/components/Footer/Footer.tsx
import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const Footer: React.FC = () => (
  <Box sx={{ bgcolor: "background.paper", py: 4 }}>
    <Container>
      <Grid
        container
        spacing={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Link to="/about">About Us</Link>
            </Grid>
            <Grid item>
              <Link to="/contact">Contact</Link>
            </Grid>
            <Grid item>
              <Link to="/terms">Terms of Service</Link>
            </Grid>
            <Grid item>
              <Link to="/privacy">Privacy Policy</Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            &copy; {new Date().getFullYear()} Media Brand Match. All rights
            reserved.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default Footer;
