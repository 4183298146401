// src/components/Pages/AboutPage.tsx
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Footer from "../Footer/Footer";

const AboutPage: React.FC = () => {
  return (
    <Box>
      {/* Main Content */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Media Brand Match is a platform dedicated to connecting media
          producers with brands for meaningful collaborations. Our mission is to
          simplify the process of product placement and brand integration,
          enabling producers to enhance their productions while providing brands
          with valuable exposure.
        </Typography>
        <Typography variant="body1" paragraph>
          Whether you're a producer looking for the perfect product to feature
          in your next project or a brand seeking new ways to reach your target
          audience, Media Brand Match is here to help.
        </Typography>
        <Typography variant="body1" paragraph>
          Our advanced smart matching system ensures that you find the best
          possible partners based on your specific needs and goals. Join us
          today and become part of a network that's shaping the future of media
          collaborations.
        </Typography>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default AboutPage;
