import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import HeroCarousel from "../HeroCarousel/HeroCarousel";
import {
  BadgeOutlined,
  FolderCopyOutlined,
  AutoFixHighOutlined,
  GroupOutlined,
  StarOutline,
  Inventory2Outlined,
  AttachMoneyRounded,
} from "@mui/icons-material";

const ProducerHomePage: React.FC = () => {
  return (
    <Box>
      <HeroCarousel role="producer" />

      <Container sx={{ mt: 8 }}>
        <Typography variant="h3" align="center" gutterBottom>
          Welcome to <span style={{ color: "#f0a500" }}>Media Brand Match</span>
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          Media Brand Match is a cutting-edge platform tailored for media
          producers seeking meaningful partnerships with leading brands. Enhance
          your production quality, gain access to valuable resources, and secure
          funding through strategic collaborations.
        </Typography>
      </Container>

      <Container sx={{ mt: 10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          How We Empower Producers
        </Typography>
        <Grid container spacing={4} sx={{ mt: 5 }}>
          {/* Feature 1 */}
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={StarOutline}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 100 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Enhance Production
                </Typography>
                <Typography variant="body1">
                  Real product placements and collaborations with brands that
                  can elevate your production quality.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={Inventory2Outlined}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 80 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Access Resources
                </Typography>
                <Typography variant="body1">
                  Discover brands that can provide locations, props, and other
                  essential resources for your projects.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 5 }}>
            <Card sx={{ height: "100%", textAlign: "center", padding: 2 }}>
              <CardMedia
                component={AttachMoneyRounded}
                color="secondary"
                sx={{ height: 140, mx: "auto", fontSize: 100 }}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Financial Support
                </Typography>
                <Typography variant="body1">
                  Secure funding through collaborations with brands that value
                  your creative vision.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          How It Works
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <BadgeOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Register as Producer
              </Typography>
              <Typography variant="body1">
                Create a detailed profile showcasing your production expertise
                and portfolio.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <FolderCopyOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Add your projects
              </Typography>
              <Typography variant="body1">
                For each project, indicate the type of brand partnership you are
                looking for.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <AutoFixHighOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Intelligent Matching
              </Typography>
              <Typography variant="body1">
                Our smart algorithms connect you with the best partners tailored
                to your specific needs and goals.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: "center" }}>
              <GroupOutlined style={{ fontSize: 80 }} color="secondary" />
              <Typography variant="h6" gutterBottom>
                Collaborate on Projects
              </Typography>
              <Typography variant="body1">
                Initiate and manage collaborations with brands to bring your
                creative visions to life.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProducerHomePage;
