// src/theme.ts
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light", // Switch to light mode for better contrast with the new background
    primary: {
      main: "#0D1B2A", // Navy Blue
    },
    secondary: {
      main: "#F0A500", // Gold
    },
    background: {
      default: "#F0F2F5", // Soft light gray background
      paper: "#FFFFFF", // White surfaces for cards and paper components
    },
    text: {
      primary: "#1C1E21", // Dark slate gray text for high readability
      secondary: "#606770", // Medium gray for secondary text
    },
    error: {
      main: "#E53935", // Error red
    },
    success: {
      main: "#43A047", // Success green
    },
    divider: "#E0E0E0", // Light gray divider
    info: {
      main: "#008080", // Teal accent color
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      fontSize: "1.75rem",
      color: "#1C1E21",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      color: "#1C1E21",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      color: "#1C1E21",
    },
  },
});

export default theme;
