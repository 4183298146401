// src/App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import HomePage from "./components/HomePage/HomePage";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import TermsPage from "./components/Pages/TermsPage";
import PrivacyPolicyPage from "./components/Pages/PrivacyPolicyPage";
import Navbar from "./components/Common/Navbar";
import Waitlist from "./components/Waitlist/Waitlist";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
        scriptProps={{
          async: true,
          defer: true,
          appendTo: "head",
          nonce: undefined,
        }}
      >
        <Router>
          <AppContent />
        </Router>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
};

const AppContent: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract the role from the pathname
  const pathRole = location.pathname.split("/")[1];
  const validRoles = ["producer", "brand"];
  const role = validRoles.includes(pathRole)
    ? (pathRole as "producer" | "brand")
    : "producer";

  // Handle role change
  const handleRoleChange = (newRole: "producer" | "brand") => {
    if (newRole !== role) {
      navigate(`/${newRole}`);
    }
  };

  return (
    <>
      <Navbar role={role} onRoleChange={handleRoleChange} />
      <Routes>
        <Route path="/" element={<Navigate to={`/${role}`} replace />} />
        <Route path="/producer" element={<HomePage role="producer" />} />
        <Route path="/brand" element={<HomePage role="brand" />} />
        <Route path="/waitlist" element={<Waitlist />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        {/* Add other routes as needed */}
        <Route path="*" element={<Navigate to={`/${role}`} replace />} />
      </Routes>
    </>
  );
};

export default App;
